import { React, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Subscribe from "../components/Subscribe";
import { MetaTags } from "react-meta-tags";
import { Helmet } from "react-helmet";
const Terms = ({ data, metaData }) => {
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.title + " - Terms"} </title>
        <meta name="description" content={metaData?.description} />
        <meta property="og:title" content={metaData?.title + "- Terms"} />
        <meta property="og:description" content={metaData?.description} />
        <meta property="og:image" content={metaData?.imageUrl} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content={metaData?.title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData?.title + "- Terms"} />
        <meta name="twitter:description" content={metaData?.description} />
        <meta name="twitter:image" content={metaData?.imageUrl} />
      </Helmet>
      <Header />
      <section className="content">
        <img
          className="poa"
          src="https://firebasestorage.googleapis.com/v0/b/wiftz-podcasts.appspot.com/o/innerpages.png?alt=media&token=7a6de194-53eb-4057-8810-d2692761ce93"
        />
        <div className="container">
          <div class="pt-90 pb-90">
            <div class="hero-content ">
              <center>
                <h2>Terms of Use</h2>
              </center>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="innerContent hidden">
            <div
              className="text-light"
              dangerouslySetInnerHTML={{
                __html: data?.terms,
              }}
            />
          </div>
        </div>
      </section>
      <Subscribe />
      <Footer />
    </>
  );
};

export default Terms;
