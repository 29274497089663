import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// production
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "wiftz-podcasts.firebaseapp.com",
  projectId: "wiftz-podcasts",
  storageBucket: "wiftz-podcasts.appspot.com",
  messagingSenderId: "42188632923",
  appId: "1:42188632923:web:fe111d1054c5850054e07e",
};

// stagging
// const firebaseConfig = {
//   apiKey: "AIzaSyDJOiUOdrt-CIB4K1VS8GSRX2N5-PTqSgk",
//   authDomain: "testifypro-7983b.firebaseapp.com",
//   projectId: "testifypro-7983b",
//   storageBucket: "testifypro-7983b.appspot.com",
//   messagingSenderId: "1075302473115",
//   appId: "1:1075302473115:web:24b9e6a87d10db48bf153e",
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
