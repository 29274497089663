import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const BlogDetails = ({ loading }) => {
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const [posts, setPosts] = useState();
  const [active, setActive] = useState(null);
  useEffect(() => {
    setActive("Blog");
    // id && getPodcastDetails();
  }, [id]);

  const location = useLocation();
  const socialLink = location.pathname;
  useEffect(() => {
    const getData = async (e) => {
      const PodcastData = query(
        collection(db, "Posts"),
        where("url", "==", id)
      );
      const querySnapshot = await getDocs(PodcastData);
      querySnapshot.forEach((doc) => {
        setPosts({ ...doc.data() });
      });
    };
    getData();
  }, []);

  const back = "<";
  return (
    <>
      <Helmet>
        <title> {"Wiftz Blog - " + posts?.title}</title>
        <meta
          name="description"
          content={
            posts?.description.replace(/(<([^>]+)>)/gi, "").slice(0, 165) +
            "..."
          }
        />
        <meta
          property="og:description"
          content={
            posts?.description.replace(/(<([^>]+)>)/gi, "").slice(0, 165) +
            "..."
          }
        />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:title" content={posts?.title} />
        <meta property="og:image" content={posts?.FeaturedImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Header active={active} />

      <div
        className="headx"
        style={{
          backgroundImage: `url(${posts?.FeaturedImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <span
          style={{ display: "block" }}
          className="green-overlay-blog"
        ></span>
        <div className="wrapper">
          <br />
          <div className="breadcrumbs">
            <ul className="flex">
              <Link to="/blog">
                <span style={{ marginRight: "10px" }}>{back}</span>Back to Blog{" "}
              </Link>
            </ul>
          </div>
          <br />
        </div>
        <div className="wrapper innerPodcast">
          <div className="row pt-90"></div>
          <div className="row pt-90"></div>
          <center>
            <h1 className="title title-x innerContent">{posts?.title}</h1>
          </center>
          <div className="row pb-50"></div>
          <div className="row pb-50"></div>
        </div>
      </div>
      <div className="wrapper">
        <div className="custom-position-blog thumbnail">
          <div className="row">
            <div className="col-6 p-10">
              <span className="text-light fs-10px">
                <strong>
                  <img
                    className="ico  w-20px"
                    src="https://img.icons8.com/ffffff/file"
                  />
                </strong>
                {posts?.category}
              </span>
            </div>
            <div className="col-6 p-10">
              <span className="text-light">
                <img
                  className="ico w-20px"
                  src="https://img.icons8.com/ffffff/clock"
                />
              </span>

              <span className="text-light fs-10px">{posts?.timestamp}</span>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="innerContent text-light">
            <img src={posts?.FeaturedImage} className="w-100" />
            <br />
            <br />
            <div
              class="post-data"
              dangerouslySetInnerHTML={{
                __html: posts?.description,
              }}
            />
          </div>
        </div>
        <br />
        <Subscribe />
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;
