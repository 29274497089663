import message from "../assets/images/toast.png";
const Success = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div className="modal" tabIndex={-1} id="kt_modal_1">
      <div className="s-modal-dialog">
        <div className="s-modal-content">
          <button onClick={onClose} className="s-close-modal">
            X
          </button>
          <img src={message} />
        </div>
      </div>
    </div>
  );
};

export default Success;
