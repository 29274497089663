import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import Header from "../components/Header";
import Subscribe from "../components/Subscribe";
import ListenOn from "../components/ListenOn";
import AboutDetails from "../components/AboutDetails";
import Footer from "../components/Footer";
import Ico from "../assets/images/icos.png";
import { db } from "../firebase";
import Modal from "../components/Modal";
import AboutText from "../components/AboutText";
import { Helmet } from "react-helmet";

const About = ({ metaData }) => {
  const [openModal, setOpenModel] = useState(false);
  const [data, setData] = useState();

  // prod
  const id = "Cw1TrtdA382NCnAzNcIu";

  // dev
  // const id = "vpmEfY5KkO2YzMEgTSXB";

  const [screen, setScreen] = useState();
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(null);
  // push to top page after loading
  useEffect(() => {
    setActive("About");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.screen.availWidth < 760) {
      setScreen("Mobile");
    } else {
      setScreen("DesKtop");
    }
  });

  useEffect(() => {
    id && getAboutDataFromDB();
    setTimeout(function () {
      const hiddenElements = document.querySelector(".structure");
      hiddenElements.style.opacity = "1";
      console.log(hiddenElements);
    }, 4000);
  }, [id]);

  const getAboutDataFromDB = async () => {
    setLoading(true);
    const docRef = doc(db, "AboutDetails", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
    setLoading(false);
  };

  console.log(data);
  return (
    <>
      <Helmet>
        <title>{metaData?.title + "- About"}</title>
        <meta name="description" content={metaData?.description} />
        <meta property="og:title" content={metaData?.title + " - About"} />
        <meta property="og:description" content={metaData?.description} />
        <meta property="og:image" content={metaData?.imageUrl} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content={metaData?.title + "- About"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData?.title + "- About"} />
        <meta name="twitter:description" content={metaData?.description} />
        <meta name="twitter:image" content={metaData?.imageUrl} />
      </Helmet>
      <Modal open={openModal} onClose={() => setOpenModel(false)} />
      <Header active={active} />
      <div
        className="heroA"
        style={{
          backgroundImage: `url(${
            screen == "Mobile" ? data?.heroBannerMobile : data?.bannerImg
          })`,
          backgroundPosition: "center",
        }}
      >
        {/* <img className="hero-img" src={Banner} alt="" /> */}
        <div className="wrapper">
          <div className="row">
            <div className="pd-intro col-md-6 col-sm-12 wrapper hidden">
              <div className="hero-content">
                <h2 className="abt-text ">
                  <div dangerouslySetInnerHTML={{ __html: data?.title1 }} />
                </h2>
                <br />
              </div>
              <div
                className="text-content "
                dangerouslySetInnerHTML={{
                  __html: data?.subtitle1,
                }}
              />

              <button
                style={{ width: "150px", opacity: "0" }}
                onClick={() => setOpenModel(!openModal)}
                className="structure primary btn large"
              >
                Listen
              </button>
            </div>
            <div className="col-md-6 col-sm-12"></div>
          </div>
          <br />
        </div>
        <img className="ico-img" src={Ico} alt="" />
      </div>
      <ListenOn />
      <AboutDetails data={data} />
      <AboutText data={data} />
      <Subscribe />
      <Footer />
    </>
  );
};

export default About;
