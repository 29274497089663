import { Link } from "react-router-dom";

const ResourceCategory = ({ data }) => {
  function titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  return (
    <>
      <div class="row mu pt-10 wrapper">
        {data?.map((data, index) => (
          <Link
            class="nol x-4 col-sm-12  pod-content"
            to={`/resources/${data.url}`}
          >
            <div className="image-container">
              <span class="green-overlay"></span>
              <img
                className="w-100"
                src={data.FeaturedImage}
                alt={data.title}
              />
            </div>
            <h3 class="title ">{titleCase(data?.url.split("-").join(" "))}</h3>
            <p class="podcast-desc">
              <div>{data.Category}</div>
            </p>
          </Link>
        ))}
      </div>
    </>
  );
};

export default ResourceCategory;
