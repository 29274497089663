import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../components/Header";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-scroll";
import { Link as Url } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Top from "../assets/images/top.png";
import { Helmet } from "react-helmet";
const ResourceDetails = ({ resourceId }) => {
  const [screen, setScreen] = useState();
  const { id } = useParams();
  const [resources, setResources] = useState();
  const [a, setA] = useState();
  const [b, setB] = useState();
  const [c, setC] = useState();
  const [d, setD] = useState();
  const [e, setE] = useState();
  const [f, setF] = useState();
  const [g, setG] = useState();
  const [h, setH] = useState();
  const [i, setI] = useState();
  const [j, setJ] = useState();
  const [k, setK] = useState();
  const [l, setL] = useState();
  const [m, setM] = useState();
  const [n, setN] = useState();
  const [o, setO] = useState();
  const [p, setP] = useState();
  const [q, setQ] = useState();
  const [r, setR] = useState();
  const [s, setS] = useState();
  const [t, setT] = useState();
  const [u, setU] = useState();
  const [v, setV] = useState();
  const [w, setW] = useState();
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [z, setZ] = useState();
  const [sort, setSort] = useState();
  const [select, setSelect] = useState();
  const [linkData, setLinkData] = useState();
  const [active, setActive] = useState(null);

  const mystyle = {
    top: "-80px",
    backgroundImage: `url(${
      screen == "Mobile"
        ? "https://firebasestorage.googleapis.com/v0/b/wiftz-podcasts.appspot.com/o/podcastdetailsbg.png?alt=media&token=410f464e-75b7-4b7d-9be0-7b334b0e060a "
        : "https://firebasestorage.googleapis.com/v0/b/wiftz-podcasts.appspot.com/o/HPhO8ygY.png?alt=media&token=9b0f0d24-717b-4145-afbc-bc08dfcea477"
    })`,
  };

  useEffect(() => {
    const myID = document.getElementById("sttop");
    console.log(myID);
    var myScrollFunc = function () {
      var y = window.scrollY;
      if (y >= 900) {
        myID.className = "cta show";
      } else {
        myID.className = "cta hide";
      }
    };

    window.addEventListener("scroll", myScrollFunc);

    setActive("Resources");

    const getA = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "A"),
        orderBy("sort", "asc"),
        where("id", "==", id)
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setA(topList);
    };
    getA();

    const getB = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "B"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setB(topList);
    };
    getB();
    const getC = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "C"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setC(topList);
    };
    getC();
    const getD = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "D"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setD(topList);
    };
    getD();
    const getE = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "E"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setE(topList);
    };
    getE();
    const getF = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "F"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setF(topList);
    };
    getF();
    const getG = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "G"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setG(topList);
    };
    getG();
    const getH = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "H"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setH(topList);
    };
    getH();
    const getI = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "I"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setI(topList);
    };
    getI();

    const getJ = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "J"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setJ(topList);
    };
    getJ();
    const getK = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "K"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setK(topList);
    };
    getK();
    const getL = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "L"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setL(topList);
    };
    getL();
    const getM = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "M"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setM(topList);
    };
    getM();

    const getN = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "N"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setN(topList);
    };
    getN();
    const getO = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "O"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setO(topList);
    };
    getO();
    const getP = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "P"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setI(topList);
    };
    getP();
    const getQ = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "Q"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setQ(topList);
    };
    getQ();
    const getR = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "R"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ ...doc.data().data, class: doc.data().class });
      });
      setR(topList);
    };
    getR();
    const getS = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "S"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setS(topList);
    };
    getS();
    const getT = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "T"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setT(topList);
    };
    getT();

    const getU = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "U"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setU(topList);
    };
    getU();

    const getV = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "V"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setV(topList);
    };
    getV();

    const getW = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "W"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setW(topList);
    };
    getW();

    const getX = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "X"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setX(topList);
    };
    getX();

    const getY = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "Y"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setY(topList);
    };
    getY();

    const getZ = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("class", "==", "Z"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
        });
      });
      setZ(topList);
    };
    getZ();
  }, []);

  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "Resources"),
        where("url", "==", id)
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });
      // setGetId(resources);
      setResources(...topList);
    };
    getData();

    const getAll = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        orderBy("sort", "asc"),
        where("id", "==", id)
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        // console.log(doc.data().data.category);
        topList.push({
          ...doc.data().data,
          class: doc.data().class,
          category: doc.data().data.category,
        });
      });
      setLinkData(topList);
    };
    getAll();
  }, []);

  const handleSort = (e) => {
    // console.log(linkData);
    if (e.target.value == "All") {
      document.getElementById("sort").innerText = e.target.value;
      const A = linkData.filter((linkData) => {
        return linkData.class == "A";
      });
      setA(A);

      const B = linkData.filter((linkData) => {
        return linkData.class == "B";
      });
      setB(B);

      const C = linkData.filter((linkData) => {
        return linkData.class == "C";
      });
      setC(C);

      const D = linkData.filter((linkData) => {
        return linkData.class == "D";
      });
      setD(D);

      const E = linkData.filter((linkData) => {
        return linkData.class == "E";
      });
      setE(E);

      const F = linkData.filter((linkData) => {
        return linkData.class == "F";
      });
      setF(F);

      const G = linkData.filter((linkData) => {
        return linkData.class == "G";
      });
      setG(G);

      const H = linkData.filter((linkData) => {
        return linkData.class == "H";
      });
      setH(H);

      const I = linkData.filter((linkData) => {
        return linkData.class == "I";
      });
      setI(I);

      const J = linkData.filter((linkData) => {
        return linkData.class == "J";
      });
      setJ(J);

      const K = linkData.filter((linkData) => {
        return linkData.class == "K";
      });
      setK(K);

      const L = linkData.filter((linkData) => {
        return linkData.class == "L";
      });
      setL(L);

      const M = linkData.filter((linkData) => {
        return linkData.class == "M";
      });
      setM(M);

      const N = linkData.filter((linkData) => {
        return linkData.class == "N";
      });
      setN(N);

      const O = linkData.filter((linkData) => {
        return linkData.class == "O";
      });
      setO(O);

      const P = linkData.filter((linkData) => {
        return linkData.class == "P";
      });
      setP(P);

      const Q = linkData.filter((linkData) => {
        return linkData.class == "Q";
      });
      setQ(Q);

      const R = linkData.filter((linkData) => {
        return linkData.class == "R";
      });
      setR(R);

      const S = linkData.filter((linkData) => {
        return linkData.class == "S";
      });
      setS(S);

      const T = linkData.filter((linkData) => {
        return linkData.class == "T";
      });
      setT(T);

      const U = linkData.filter((linkData) => {
        return linkData.class == "U";
      });
      setU(U);

      const V = linkData.filter((linkData) => {
        return linkData.class == "V";
      });
      setV(V);

      const W = linkData.filter((linkData) => {
        return linkData.class == "W";
      });
      setW(W);

      const X = linkData.filter((linkData) => {
        return linkData.class == "X";
      });
      setX(X);

      const Y = linkData.filter((linkData) => {
        return linkData.class == "Y";
      });
      setY(Y);

      const Z = linkData.filter((linkData) => {
        return linkData.class == "Z";
      });
      setZ(Z);
    } else {
      document.getElementById("sort").innerText = e.target.value;
      const A = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "A";
      });
      setA(A);

      const B = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "B";
      });
      setB(B);

      const C = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "C";
      });
      setC(C);

      const D = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "D";
      });
      setD(D);

      const E = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "E";
      });
      setE(E);

      const F = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "F";
      });
      setF(F);

      const G = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "G";
      });
      setG(G);

      const H = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "H";
      });
      setH(H);

      const I = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "I";
      });
      setI(I);

      const J = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "J";
      });
      setJ(J);

      const K = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "K";
      });
      setK(K);

      const L = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "L";
      });
      setL(L);

      const M = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "M";
      });
      setM(M);

      const N = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "N";
      });
      setN(N);

      const O = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "O";
      });
      setO(O);

      const P = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "P";
      });
      setP(P);

      const Q = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "Q";
      });
      setQ(Q);

      const R = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "R";
      });
      setR(R);

      const S = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "S";
      });
      setS(S);

      const T = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "T";
      });
      setT(T);

      const U = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "U";
      });
      setU(U);

      const V = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "V";
      });
      setV(V);

      const W = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "W";
      });
      setW(W);

      const X = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "X";
      });
      setX(X);

      const Y = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "Y";
      });
      setY(Y);

      const Z = linkData.filter((linkData) => {
        return linkData.category == e.target.value && linkData.class == "Z";
      });
      setZ(Z);
    }
  };

  useEffect(() => {
    const sort = async (e) => {
      let topList = [];
      let category = [];
      const Resources = query(
        collection(db, "ResourcesItems"),
        where("id", "==", id),
        orderBy("sort", "asc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push(...doc.data().class);
        category.push(doc.data().data.category);
      });

      const uniqueTags = [...new Set(topList)];
      const uniqueCat = [...new Set(category)];
      uniqueTags.sort();
      uniqueCat.sort();
      setSort(uniqueTags);
      setSelect(uniqueCat);
    };
    sort();
  }, []);
  const back = "<";
  // console.log(h);
  const location = useLocation();
  // console.log(select);
  // console.log(sort);
  const socialLink = location.pathname;
  return (
    <>
      <Helmet>
        <title>
          {"Wiftz Resources - " + resources?.url.split("-").join(" ")}
        </title>
        <meta
          name="description"
          content={
            resources?.description.replace(/(<([^>]+)>)/gi, "").slice(0, 165) +
            "..."
          }
        />
        <meta
          property="og:description"
          content={
            resources?.description.replace(/(<([^>]+)>)/gi, "").slice(0, 165) +
            "..."
          }
        />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="Wiftz Resources" />
        <meta property="og:title" content={resources?.title} />
        <meta property="og:image" content={resources?.featuredImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Header active={active} />
      <div
        className="heroR"
        style={{
          backgroundImage: `url(${resources?.FeaturedImage})`,
        }}
      >
        <div className="resource-overlay"></div>
        <div className="wrapper">
          <br />

          <img
            className="hide-large"
            style={{ position: "absolute", top: "80px", width: "93%" }}
            src={resources?.featuredImage}
          />
          <div className="pt-200"></div>
          <div className="breadcrumbs" style={{ top: "130px" }}>
            <ul className="flex">
              <Url to="/resources">
                <span style={{ marginRight: "10px" }}>{back}</span>Back to
                resources
              </Url>
            </ul>
          </div>
          <div className="hero-content hidden">
            <h2 dangerouslySetInnerHTML={{ __html: resources?.title }} />
            <p className="text-light mw-50px">{resources?.subtitle}</p>
          </div>
        </div>
      </div>
      <div className="wrapper custom-position">
        <div className="featured-high" style={{ backgroundColor: "#0D0E18" }}>
          <div className=" latest-podcast  pt-20">
            <div className="wrapper ">
              <div className="hidden">
                <p
                  className="text-light"
                  dangerouslySetInnerHTML={{
                    __html: resources?.description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <section className="latest-resource">
          <br />
          <div className="pt-50">
            <br />
            <div className="row">
              <div className="col-md-6 col-s-12">
                <h2 className="sort-title">
                  A - Z Directory |&nbsp;
                  <span style={{ color: "#585AA8" }} id="sort">
                    All
                  </span>
                </h2>
              </div>
              <div className="col-md-6 col-s-12">
                <div className="flex">
                  <p className="res-cat">Select Category</p>

                  <select className="res-select" onChange={handleSort}>
                    <option value="All">All</option>
                    {select?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                        <hr />
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="sort-list">
              {sort?.map((item, index) => (
                <span>
                  <Link
                    key={index}
                    to={item}
                    duration={50}
                    offset={-200}
                    spy={true}
                    smooth={true}
                  >
                    {item}
                  </Link>
                </span>
              ))}
            </div>
            <br />
            <div className="row pt-20" style={{ padding: "0 20px" }}>
              {a?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="A" className="text-light">
                      A
                    </h3>
                    <ul className="resource-list">
                      {a?.map((item, index) => (
                        <div className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${item?.Link}`}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}

              {b?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="B" className="text-light">
                      B
                    </h3>
                    <ul className="resource-list">
                      {b?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {c?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="C" className="text-light">
                      C
                    </h3>
                    <ul className="resource-list">
                      {c?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {d?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="D" className="text-light">
                      D
                    </h3>
                    <ul className="resource-list">
                      {d?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {e?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="E" className="text-light">
                      E
                    </h3>
                    <ul className="resource-list">
                      {e?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {f?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="F" className="text-light">
                      F
                    </h3>
                    <ul className="resource-list">
                      {f?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {g?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="G" className="text-light">
                      G
                    </h3>
                    <ul className="resource-list">
                      {g?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {h?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="H" className="text-light">
                      H
                    </h3>
                    <ul className="resource-list">
                      {h?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {i?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="I" className="text-light">
                      I
                    </h3>
                    <ul className="resource-list">
                      {i?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {j?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="J" className="text-light">
                      J
                    </h3>
                    <ul className="resource-list">
                      {j?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {k?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="K" className="text-light">
                      K
                    </h3>
                    <ul className="resource-list">
                      {k?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {l?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="L" className="text-light">
                      L
                    </h3>
                    <ul className="resource-list">
                      {l?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {m?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="M" className="text-light">
                      M
                    </h3>
                    <ul className="resource-list">
                      {m?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {n?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="N" className="text-light">
                      N
                    </h3>
                    <ul className="resource-list">
                      {n?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {o?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="O" className="text-light">
                      O
                    </h3>
                    <ul className="resource-list">
                      {o?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {p?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="P" className="text-light">
                      P
                    </h3>
                    <ul className="resource-list">
                      {p?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {q?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="Q" className="text-light">
                      Q
                    </h3>
                    <ul className="resource-list">
                      {q?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {r?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="R" className="text-light">
                      R
                    </h3>
                    <ul className="resource-list">
                      {r?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {s?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="S" className="text-light">
                      S
                    </h3>
                    <ul className="resource-list">
                      {s?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {t?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="T" className="text-light">
                      T
                    </h3>
                    <ul className="resource-list">
                      {t?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {u?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="U" className="text-light">
                      U
                    </h3>
                    <ul className="resource-list">
                      {u?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {v?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="V" className="text-light">
                      V
                    </h3>
                    <ul className="resource-list">
                      {v?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {w?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="W" className="text-light">
                      W
                    </h3>
                    <ul className="resource-list">
                      {w?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {x?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="X" className="text-light">
                      X
                    </h3>
                    <ul className="resource-list">
                      {x?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {y?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="Y" className="text-light">
                      Y
                    </h3>
                    <ul className="resource-list">
                      {y?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
              {z?.length > 0 ? (
                <>
                  <div className=" mb-20 col-md-6 col-s-12">
                    <h3 id="Z" className="text-light">
                      Z
                    </h3>
                    <ul className="resource-list">
                      {z?.map((item, index) => (
                        <li className="row" key={index}>
                          <li className="col list-item">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.Text}
                            </a>
                          </li>
                          <li className="col list-itema">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item?.Link}
                            >
                              {item?.buttonName + " " + ">"}
                            </a>
                          </li>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <span
            id="sttop"
            className="hide"
            onClick={() => {
              window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
            }}
            style={{
              position: "fixed",
              bottom: "100px",
              right: "200px",
              cursor: "pointer",
            }}
          >
            <img style={{ width: "200px" }} src={Top} />
          </span>
        </section>
      </div>
      <div style={{ padding: "20px" }}>
        <Subscribe />
      </div>
      <Footer />
    </>
  );
};

export default ResourceDetails;
