import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminContent from "../components/AdminContent";
import AdminHeader from "../components/AdminHeader";
import { db } from "../firebase";
const initialState = {
  title: "",
  keywords: "",
  description: "",
  imageUrl: "",
};

const AdminMetaData = (user, handleLogout) => {
  const [form, setForm] = useState(initialState);

  const { title, keywords, description, imageUrl } = form;
  const navigate = useNavigate();

  // prod
  const id = "9XbFZ0hkUC35jS5753me";

  // dev
  // const id = "nvi52ZBZnd4dpzEYo4Me";
  useEffect(() => {
    id && getPolicyDetails();
  }, [id]);

  const getPolicyDetails = async () => {
    const docRef = doc(db, "MetaData", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm(snapshot.data());
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "MetaData", id), {
        // await addDoc(collection(db, "MetaData"), {
        ...form,
      });

      toast.success("Meta Data Created Successfully");
    } catch (err) {
      console.log(err);
    }

    navigate("/admin/metadata/editor");
  };

  return (
    <>
      <AdminHeader user={user} handleLogout={handleLogout} />

      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-5 order-2 order-lg-1">
            <div className="card card-custom">
              <div className="card-header">
                <div className="card-title">
                  <h1 className=" fw-bolder mb-3">Update Meta Data</h1>
                </div>
                <div className="card-toolbar">
                  <Link to="/admin">
                    <button type="button" className="btn btn-sm btn-primary">
                      CANCEL
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card-scroll">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                  <div className="" style={{ width: "95%" }}>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Site Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        autocomplete="off"
                        value={title}
                        className="form-control bg-transparent"
                        onChange={handleChange}
                      />

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Site Description
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        value={description}
                        autocomplete="off"
                        className="form-control bg-transparent"
                        onChange={handleChange}
                      ></textarea>

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Site Keywords
                      </label>
                      <textarea
                        type="text"
                        name="keywords"
                        value={keywords}
                        autocomplete="off"
                        className="form-control bg-transparent"
                        onChange={handleChange}
                      ></textarea>

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <div class="fv-row mb-8 fv-plugins-icon-container">
                        <div class="mb-10">
                          {imageUrl ? (
                            <img style={{ width: "200px" }} src={imageUrl} />
                          ) : (
                            ""
                          )}
                          <br />
                          <label>Site Image</label>
                          <input
                            type="text"
                            placeholder="Site Image Link"
                            name="imageUrl"
                            autocomplete="off"
                            value={imageUrl}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                          {/* <ReactTagInput
                              tags={FeaturedImage}
                              placeholder="Image Url Here"
                              onChange={handleImages}
                            /> */}
                        </div>
                      </div>
                    </div>
                    <center>
                      <button
                        style={{ padding: "10px", width: "250px" }}
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit}
                        id="kt_sign_in_submit"
                      >
                        UPDATE
                      </button>
                      <br />
                      <br />
                      <br />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* side two */}
        </div>
      </div>

      <AdminContent user={user} />
    </>
  );
};

export default AdminMetaData;
