import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Subscribe from "../components/Subscribe";
import { db } from "../firebase";
import Button from "../components/Button";
// import { MetaTags } from "react-meta-tags";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const BlogPage = ({ metaData }) => {
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pagedata, setPageData] = useState();
  const [featured, setFeatured] = useState();
  const [active, setActive] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  // push to top page after loading
  useEffect(() => {
    setActive("Blog");
    window.scrollTo(0, 0);
  }, []);

  // setSelected("Technology");

  // prod
  const id = "tjHV4GSUrph8aGt8Rj0x";

  // dev
  // const id = "ZreeI7Zz9ASDplVtRrtx";
  useEffect(() => {
    if (dataLoading) {
      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((item) => {
        item.style.opacity = "0";
      });
    } else {
      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((item) => {
        item.style.opacity = "1";
      });
    }
    id && getPageDataFromDB();
  }, [id]);

  const getPageDataFromDB = async () => {
    setDataLoading(true);
    const docRef = doc(db, "BlogDetails", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setPageData({ ...snapshot.data() });
      setDataLoading(false);
    }
  };

  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      let tags = [];
      const Posts = query(collection(db, "Posts"), orderBy("date", "desc"));
      const querySnapshot = await getDocs(Posts);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
        tags.push(doc.data().category);
      });
      const uniqueTags = [...new Set(tags)];

      setData(topList);
      setSelected(uniqueTags[0]);
      setTags(uniqueTags);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async (e) => {
      let data = [];
      const FeaturedData = query(
        collection(db, "Posts"),
        where("trending", "==", "yes")
        // limit(1)
      );
      const querySnapshot = await getDocs(FeaturedData);
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data() });
      });
      setFeatured(data[0]);
    };
    getData();
  }, []);

  const handleTagSelect = async (e) => {
    const targetText = e.target.innerText;
    setSelected(targetText);
    const loopTags = document.querySelectorAll(".tags");
    for (let i = 0; i < loopTags.length; i++) {
      loopTags[i].classList.remove("clicked");

      if (loopTags[i].innerText === targetText) {
        loopTags[i].classList.add("clicked");
      }
    }
    if (targetText === "All") {
      const collectionRef = collection(db, "Posts");
      const topQuerry = query(collectionRef);
      onSnapshot(
        topQuerry,
        (snapshot) => {
          let topList = [];
          snapshot.docs.forEach((doc) => {
            topList.push({ id: doc.id, ...doc.data() });
            setData(topList);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      const collectionRef = collection(db, "Posts");
      const topQuerry = query(
        collectionRef,
        where("category", "==", targetText)
      );
      onSnapshot(
        topQuerry,
        (snapshot) => {
          let topList = [];
          snapshot.docs.forEach((doc) => {
            topList.push({ id: doc.id, ...doc.data() });
            setData(topList);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  // setActive("Blog");
  // console.log(data);

  return (
    <>
      <Helmet>
        <title>{metaData?.title + " - Blog"} </title>
        <meta name="description" content={metaData?.description} />
        <meta property="og:title" content={metaData?.title + " - Blog"} />
        <meta property="og:description" content={metaData?.description} />
        <meta property="og:image" content={metaData?.imageUrl} />
        <link rel="canonical" to={window.location.to} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content={metaData?.title} />
        <meta property="og:url" content={window.location.to} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header active={active} />
      <div
        className="heroB"
        style={{
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/wiftz-podcasts.appspot.com/o/Podcasts%2FBlog%20Banner.png?alt=media&token=093015fd-9dfe-4e82-8a63-0c6bf1a71b19)`,
        }}
      >
        <div className="wrapper">
          <br />
          <div className="hide-small pt-200"></div>
          <div className="hide-large pt-80"></div>
          <div className="hero-contentP hidden">
            <h2>{pagedata?.BlogheadLine}</h2>
            <p className="text-light">{pagedata?.BlogsubHeading}</p>
          </div>
        </div>
      </div>
      <section className="bg-secondary pt-50 pb-90">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="thembail-featured">
                <img
                  style={{ borderRadius: "10px" }}
                  className="w-100"
                  src={featured?.FeaturedImage}
                />
              </div>
            </div>
            {featured ? (
              <div className="col-md-6 col-sm-12">
                <div className="article pt-20" style={{ paddingLeft: "20px" }}>
                  <button className="btn date featured">
                    <span>{featured?.category}</span> &nbsp;|&nbsp;
                    <span>{featured?.timestamp}</span>
                  </button>
                  <h3
                    className="podcast-title text-light"
                    style={{ marginTop: "20px" }}
                  >
                    {featured?.title}
                  </h3>
                  <p className="podcast-desc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: featured?.shortDescription,
                      }}
                    />
                  </p>
                  <br />
                  <Link
                    to={"/blog/" + featured?.url}
                    style={{ width: "131px" }}
                  >
                    <Button
                      style="structure primary btn large mw-110"
                      text="Read more"
                    />
                  </Link>
                  <br />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      <section className="latest-podcast pt-90">
        <div className="wrapper">
          <div className="latest-container">
            <h2>{pagedata?.BlogsubHeadLine}</h2>
            <p className="text-light">{pagedata?.BlogsubHeadlineText}</p>
          </div>
        </div>
      </section>
      <div className="wrapper">
        <div className="tags scroll-h">
          <button className="tags clicked" onClick={handleTagSelect}>
            All
          </button>
          {tags?.map((tag, index) => (
            <button className="tags" key={index} onClick={handleTagSelect}>
              {tag}
            </button>
          ))}
        </div>
      </div>
      <div className="">
        <div className="row wrapper ">
          {data?.map((data, index) => (
            <Link
              key={index}
              className="x-4 col-sm-12 pod-content "
              to={"/blog/" + data?.url}
            >
              <div className="image-container">
                <span className="green-overlay"></span>
                <img
                  src={data.FeaturedImage}
                  alt={data?.title}
                  className="pod-content-img"
                />
              </div>
              <h3 className="title ">{data?.title}</h3>
              <p className="podcast-desc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.shortDescription.substring(0, 120),
                  }}
                />
              </p>
              <br />
              <div
                className="row"
                style={{ position: "absolute", bottom: "10px", width: "100%" }}
              >
                <div
                  style={{
                    paddingTop: "4px",
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                  className="col"
                >
                  {data?.timestamp}
                </div>
                <div className="col">
                  <Link className=" ptx-10x" to={"/blog/" + data?.url}>
                    Read more &gt;
                  </Link>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="wrapper">
        <Subscribe />
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
