import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import BottomBanner from "../components/BottomBanner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import LatestPodcasts from "../components/LatestPodcasts";
import ListenOn from "../components/ListenOn";
import Subscribe from "../components/Subscribe";
import TopPodcast from "../components/TopPodcast";
import { db } from "../firebase";
import { Helmet } from "react-helmet";

const Home = ({ featured, topList, loading, popup, metaData }) => {
  const [data, setData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  // prod
  const id = "TgcagxHqMIpvRTMnsjU4";

  // dev
  // const id = "tM2sKnh9bSfpyvARBvkj";

  useEffect(() => {
    id && getHomeDataFromDB();
  }, [id]);

  const getHomeDataFromDB = async () => {
    setDataLoading(true);
    const docRef = doc(db, "HomeDetails", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
      setDataLoading(false);
    }
  };
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.title}</title>
        <meta name="description" content={metaData?.description} />
        <meta property="og:title" content={metaData?.title} />
        <meta property="og:description" content={metaData?.description} />
        <meta property="og:image" content={metaData?.imageUrl} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content={metaData?.title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData?.title} />
        <meta name="twitter:description" content={metaData?.description} />
        <meta name="twitter:image" content={metaData?.imageUrl} />
      </Helmet>
      <Header />
      <Hero loading={dataLoading} data={data} />
      <ListenOn />
      <LatestPodcasts featured={featured} data={data} bg={"black"} />
      <BottomBanner data={data} />
      <TopPodcast topList={topList} data={data} loading={loading} />
      <div className="wrapper">
        <Subscribe data={data} />
      </div>
      <Footer data={data} popup={popup} />
    </>
  );
};

export default Home;
