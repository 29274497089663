import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Ico from "../assets/images/icos.png";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import Subscribe from "../components/Subscribe";
import ResourceCategory from "../components/ResourceCategory";
import { MetaTags } from "react-meta-tags";
import { Helmet } from "react-helmet";
const Resources = ({ metaData }) => {
  const [active, setActive] = useState(null);
  const [screen, setScreen] = useState();
  const [pageData, setPageData] = useState();
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  // prod
  const id = "Yo1vL9GrpDHTa4FyZbgd";

  // dev
  // const id = "TNpaUH9gJgQFcWhz4kRf";
  useEffect(() => {
    setActive("Resources");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window.screen.availWidth < 760) {
      setScreen("Mobile");
    } else {
      setScreen("DesKtop");
    }
  });
  useEffect(() => {
    id && getAboutDataFromDB();
  }, [id]);

  const getAboutDataFromDB = async () => {
    setDataLoading(true);
    const docRef = doc(db, "ResourcesDetails", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setPageData({ ...snapshot.data() });
      setDataLoading(false);
    }
  };
  useEffect(() => {
    const getData = async (e) => {
      let topList = [];
      const Resources = query(
        collection(db, "Resources"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(Resources);
      querySnapshot.forEach((doc) => {
        topList.push({ id: doc.id, ...doc.data() });
      });

      setData(topList);
    };
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData?.title + " - Resources"} </title>
        <meta name="description" content={metaData?.description} />
        <meta property="og:title" content={metaData?.title + "- Resources"} />
        <meta property="og:description" content={metaData?.description} />
        <meta property="og:image" content={metaData?.imageUrl} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content={metaData?.title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData?.title + "- Resources"} />
        <meta name="twitter:description" content={metaData?.description} />
        <meta name="twitter:image" content={metaData?.imageUrl} />
      </Helmet>
      <Header active={active} />
      <div
        className="heroR"
        style={{
          backgroundImage: `url(${
            screen == "Mobile" ? pageData?.banner : pageData?.banner
          })`,
          backgroundPosition: "bottom",
        }}
      >
        {/* <img className="hero-img" src={Banner} alt="" /> */}
        <div className="wrapper">
          <div className="row">
            <div className="pd-intro col-md-6 col-sm-12 hidden">
              <div className="hero-content">
                <div class="hero-content hidden">
                  <h2 className="abt-text">
                    <div
                      dangerouslySetInnerHTML={{ __html: pageData?.title }}
                    />
                  </h2>
                </div>
              </div>
              <br />
              <div
                className="hidden text-content text-light sct"
                dangerouslySetInnerHTML={{
                  __html: pageData?.subtitle,
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12"></div>
          </div>
          <br />
        </div>
        <img className="ico-img" src={Ico} alt="" />
      </div>
      <ResourceCategory data={data} />
      <div className="wrapper">
        <Subscribe />
      </div>
      <Footer />
    </>
  );
};

export default Resources;
